import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import AnimatedMask from "components/AnimatedMask"

import fadeAnimation from "utils/fadeAnimation"

const Conscious = ({ pageData }) => {
  const data = [
    {
      logo: require("assets/images/about/about-conscious-logo-1.jpg").default,
      header: "Certyfikat UL ECOLOGO®",
      description:
        "Oznacza to, że atramenty HP Latex nie zawierają niebezpiecznych składników.",
    },
    {
      logo: require("assets/images/about/about-conscious-logo-2.jpg").default,
      header: "Certyfikat UL GREENGUARD GOLD",
      description:
        "Oznacza to, że atramenty HP Latex nie zawierają niebezpiecznych składników.",
    },
  ]

  return (
    <section className="about-conscious">
      <div className="container-fluid">
        <SectionHeader
          title={pageData?.acfAbout?.bottomHeader}
          content={pageData?.acfAbout?.bottomSubHeader}
        />
        <div className="row">
          <motion.div className="col-md-7" {...fadeAnimation}>
            <div className="about-conscious__thumbnail">
              <AnimatedMask />
              <img
                src={pageData?.acfAbout?.bottomImage?.localFile?.publicURL}
                alt=""
              />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData?.acfAbout?.bottomLeftDescription,
              }}
            />
          </motion.div>

          <div className="col-md-4 offset-md-1">
            <div className="about-conscious__inner">
              <motion.div
                dangerouslySetInnerHTML={{
                  __html: pageData?.acfAbout?.bottomRightDescription,
                }}
                {...fadeAnimation}
              />
              <motion.div {...fadeAnimation}>
                <h4>Atramenty HP Latex posiadają:</h4>
                <div className="row">
                  {data?.map((item, index) => (
                    <div className="col-6" key={index}>
                      <div className="about-conscious__item">
                        <img src={item.logo} alt="" />
                        <strong>{item.header}</strong>
                        <span>{item.description}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Conscious
