import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import AnimatedMask from "components/AnimatedMask"

import fadeAnimation from "utils/fadeAnimation"

const Content = ({ pageData }) => {
  return (
    <section className="about-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 offset-lg-1">
            <motion.h4 {...fadeAnimation}>
              {pageData?.acfAbout?.mainDescription}
            </motion.h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <motion.div
              dangerouslySetInnerHTML={{
                __html: pageData?.acfAbout?.mainContent,
              }}
              {...fadeAnimation}
            />
          </div>
          <div className="col-md-6 offset-lg-1">
            <motion.div className="about-content__thumbnail" {...fadeAnimation}>
              <AnimatedMask />
              <img
                src={pageData?.acfAbout?.mainContentImage?.localFile?.publicURL}
                alt=""
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
