import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Precision, Conscious } from "page_components/about"

const About = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout>
      <Breadcrumbs title="O nas" />
      <PageHeader
        title={pageData?.acfAbout?.header}
        content={pageData?.acfAbout?.subHeader}
      />
      <Content pageData={pageData} />
      <Precision pageData={pageData} />
      <Conscious pageData={pageData} />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3MzQy" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfAbout {
          header
          subHeader
          mainDescription
          mainContent
          mainContentImage {
            localFile {
              publicURL
            }
          }
          middleHeader
          middleSubHeader
          middleDescription
          middleGallery {
            localFile {
              publicURL
            }
          }
          bottomHeader
          bottomSubHeader
          bottomLeftDescription
          bottomImage {
            localFile {
              publicURL
            }
          }
          bottomRightDescription
        }
      }
    }
  }
`

export default About
