import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"

import fadeAnimation from "utils/fadeAnimation"

const Precision = ({ pageData }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className="about-precision">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <SectionHeader
              title={pageData?.acfAbout?.middleHeader}
              content={pageData?.acfAbout?.middleSubHeader}
            />
          </div>
          <div className="col-lg-6">
            <motion.div
              dangerouslySetInnerHTML={{
                __html: pageData?.acfAbout?.middleDescription,
              }}
              {...fadeAnimation}
            />
          </div>
        </div>

        <motion.div className="row" {...fadeAnimation}>
          <div className="col-xl-11 offset-xl-1">
            <Slider {...slider}>
              {pageData?.acfAbout?.middleGallery?.map((item, index) => (
                <div key={index}>
                  <div
                    className="about-precision__item"
                    style={{
                      backgroundImage: `url('${item?.localFile?.publicURL}')`,
                    }}
                  ></div>
                </div>
              ))}
            </Slider>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default Precision
